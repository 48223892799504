img {
  display: inline-block;
  max-width: 100%;
}

.body {
  background-color: #faf7f7;
  box-shadow: 57px 57px 14px 3px rgba(67, 68, 31, 0.23);
  font-family: Assistant, sans-serif;
  color: #466289;
}

.center-content {
  position: relative;
  top: -746px;
  z-index: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 920px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsla(0, 0%, 100%, 0);
  font-family: Assistant, sans-serif;
}

.image {
  width: 120px;
  margin-top: 35px;
  margin-bottom: 35px;
}

.image.logo.headline {
  width: 400px;
  margin-top: 0px;
  margin-bottom: 73px;
}

.heading {
  background-color: #d336ae;
  font-family: 'Noto Sans', sans-serif;
  font-size: 50px;
}

.heading.top-title {
  margin-top: -10px;
  margin-left: -40px;
  padding: 7px 25px 25px;
  border-radius: 4px;
  background-image: linear-gradient(45deg, #d1289e, #9e1370);
  color: #fff;
  font-size: 43px;
  text-align: center;
}

.heading.top-title.line-2 {
  position: relative;
  top: -7px;
  margin-right: -50px;
  margin-left: 80px;
  background-image: linear-gradient(315deg, #d1289e, #9e1370);
}

.subhead {
  width: 70%;
  margin: 31px auto 50px;
  color: #466289;
  font-size: 27px;
  line-height: 32px;
  font-weight: 400;
  text-align: center;
}

.div-block-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 140px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.highlight-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 400.625px;
  height: auto;
  min-height: auto;
  margin-right: 34px;
  margin-left: 34px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: #fff;
}

.highlight-box.big-box-shadow {
  box-shadow: 6px 6px 14px 3px rgba(67, 68, 31, 0.23);
}

.highlight-box-head {
  margin-top: -23px;
  margin-bottom: 0px;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}

.point-title {
  margin-top: 2px;
  padding-left: 12px;
  color: #466289;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.02em;
}

.higlight-point {
  width: 32px;
  height: 32px;
  margin-top: 4px;
  margin-left: -16px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-style: solid;
  border-width: 6px;
  border-color: #a01572;
  border-radius: 20px;
  background-color: #fff;
}

.higlight-point.point-blue {
  border-color: #00cdcc;
}

.higlight-point.point-green {
  border-color: #0ecd00;
}

.highlight-box-paragraph {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.highlight-box-paragraph.inline {
  width: 93%;
}

.highlight-block-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.point-paragraph {
  padding-right: 18px;
  padding-bottom: 12px;
  padding-left: 32px;
  font-size: 20px;
  line-height: 26px;
}

.title-block {
  margin-top: 83px;
  margin-bottom: 100px;
}

.heading-3 {
  margin-top: 33px;
  margin-bottom: 39px;
  font-family: 'Noto Sans', sans-serif;
  line-height: 28px;
  font-weight: 400;
  text-align: center;
}

.background-block-top {
  position: relative;
  top: 0px;
  /*z-index: -1;*/
  height: 800px;
  background-color: #ff8686;
  background-image: radial-gradient(circle farthest-corner at 50% 60%, #ffe0e0, #ff8686);
  color: #ff8686;
}

.background-block-top.background-top-alt {
  background-image: radial-gradient(circle farthest-corner at 50% 60%, #e0fff5, #86beff);
}

.multi-points {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-bottom: 140px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
}

.paragraph-title {
  margin-bottom: 34px;
  font-family: 'Noto Sans', sans-serif;
  font-size: 36px;
  line-height: 40px;
  font-weight: 700;
}

.social-validation-quote {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 16px 12px 12px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-radius: 4px;
  background-color: transparent;
}

.social-validation-block {
  width: 100%;
  margin-right: auto;
  margin-bottom: 140px;
  margin-left: auto;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 6px 6px 14px 3px rgba(67, 68, 31, 0.23);
}

.social-validation-statement {
  width: 100%;
  margin-left: 0px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  font-family: 'Noto Sans', sans-serif;
  font-size: 23px;
  line-height: 35px;
  font-style: italic;
  font-weight: 700;
}

.profile-pic {
  width: 100px;
  height: 100px;
  margin-right: 24px;
  margin-left: 0px;
  border-radius: 100px;
}

.social-validation-name {
  position: static;
  width: 100%;
  margin-top: 13px;
  margin-right: 16px;
  font-size: 23px;
  text-align: right;
  text-transform: uppercase;
}

.straight-paragraph-title {
  margin-top: 2px;
  padding-left: 0px;
  color: #466289;
  font-size: 24px;
  letter-spacing: 0.02em;
}

.straight-praragraph {
  padding-right: 18px;
  padding-bottom: 12px;
  padding-left: 0px;
  font-size: 20px;
  line-height: 26px;
}

.image-2 {
  position: static;
  z-index: 1;
  float: none;
  clear: none;
}

.image-3 {
  position: relative;
  width: 61%;
  float: right;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
  border-style: solid;
  border-width: 1px;
  border-color: #aaa;
  border-radius: 4px;
}

.social-validation-text-block {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.field-label {
  font-size: 17px;
}

.background-block-bottom {
  position: absolute;
  left: -150%;
  top: auto;
  right: 0%;
  bottom: 2%;
  z-index: -1;
  width: 200vw;
  height: 600px;
  background-color: transparent;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#e0fff0), to(#d7ffc8));
  background-image: linear-gradient(180deg, #e0fff0, #d7ffc8);
  color: #ff8686;
}

.background-block-bottom.background-bottom-alt {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fce0ff), to(#c8d1ff));
  background-image: linear-gradient(180deg, #fce0ff, #c8d1ff);
}

.text-field {
  width: 200px;
}

.submit-button {
  margin-top: 20px;
  border-radius: 4px;
}

.client-info-form {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-bottom: 140px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.company-info {
  font-size: 16px;
  text-align: center;
}

.highlights-all {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.button {
  text-align: center;
}

.button-2 {
  width: auto;
  margin-right: auto;
  margin-left: auto;
  border-radius: 4px;
  font-size: 18px;
  text-transform: uppercase;
}

.button-2.to-enterprise {
  background-color: #ec38bc;
}

.div-block-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 991px) {
  .center-content {
    top: -786px;
    width: 768px;
  }
  .heading.top-title {
    margin-left: 33px;
    padding-right: 17px;
    padding-bottom: 16px;
    padding-left: 0px;
    font-size: 33px;
  }
  .heading.top-title.line-2 {
    margin-right: 44px;
    margin-left: 0px;
    padding-bottom: 10px;
  }
  .highlight-box.big-box-shadow {
    width: 300px;
  }
  .highlight-box-paragraph.inline {
    width: 100%;
  }
  .title-block {
    margin: 11px 10px 45px;
  }
  .multi-points {
    margin-right: 38px;
    margin-left: 38px;
  }
  .social-validation-quote {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .social-validation-block {
    width: 98%;
  }
  .profile-pic {
    margin-bottom: 14px;
    padding-bottom: 0px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  .image-2 {
    width: 80vw;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .background-block-bottom {
    left: -75%;
    bottom: 2%;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
  }
  .client-info-form {
    padding-right: 32px;
    padding-left: 32px;
  }
}

@media (max-width: 767px) {
  .center-content {
    width: 570px;
  }
  .div-block-2 {
    margin-bottom: 60px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .highlight-box.big-box-shadow {
    width: 90vw;
    margin-bottom: 44px;
  }
  .highlight-box-paragraph.inline {
    width: 101%;
  }
  .title-block {
    margin-right: 10px;
    margin-left: 10px;
  }
  .multi-points {
    margin-bottom: 60px;
  }
  .social-validation-block {
    width: 97%;
  }
  .image-2 {
    width: 120vw;
    margin-bottom: 24px;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }
  .background-block-bottom {
    bottom: 2%;
  }
  .highlights-all {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media (max-width: 479px) {
  .center-content {
    width: 320px;
  }
  .image.logo.headline {
    width: 80vw;
    margin-top: 11px;
    margin-bottom: 27px;
  }
  .heading.top-title {
    font-size: 24px;
    line-height: 31px;
  }
  .heading.top-title.line-2 {
    padding-left: 11px;
  }
  .subhead {
    margin-bottom: 42px;
    font-size: 24px;
    line-height: 28px;
  }
  .div-block-2 {
    margin-bottom: 36px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .highlight-box.big-box-shadow {
    margin-bottom: 41px;
  }
  .highlight-box-head {
    font-size: 14px;
  }
  .point-title {
    margin-bottom: 0px;
    font-size: 22px;
  }
  .higlight-point.point-red {
    width: 20px;
    height: 20px;
    margin-top: 12px;
    margin-left: -9px;
  }
  .higlight-point.point-blue {
    width: 20px;
    height: 20px;
    margin-top: 11px;
    margin-left: -10px;
  }
  .higlight-point.point-green {
    width: 20px;
    height: 20px;
    margin-top: 11px;
    margin-left: -10px;
  }
  .highlight-box-paragraph.inline {
    width: 100%;
  }
  .point-paragraph {
    padding-left: 24px;
  }
  .title-block {
    padding-right: 12px;
    padding-left: 12px;
  }
  .heading-3 {
    margin-top: 24px;
    margin-bottom: 24px;
    font-size: 24px;
  }
  .background-block-top {
    background-image: radial-gradient(circle farthest-corner at 50% 48%, #ffe0e0, #ff8686);
  }
  .multi-points {
    margin-right: 16px;
    margin-bottom: 56px;
    margin-left: 16px;
  }
  .paragraph-title {
    font-size: 26px;
    line-height: 32px;
  }
  .social-validation-block {
    margin-right: 34px;
    margin-bottom: 80px;
    margin-left: 34px;
  }
  .social-validation-statement {
    font-size: 18px;
    line-height: 29px;
  }
  .image-2 {
    position: static;
    width: 100vw;
  }
  .background-block-bottom {
    bottom: 1%;
  }
  .client-info-form {
    margin-bottom: 60px;
  }
}

@font-face {
  font-family: 'Material design iconic font';
  src: url('../fonts/Material-Design-Iconic-Font.woff2') format('woff2'), url('../fonts/Material-Design-Iconic-Font.eot') format('embedded-opentype'), url('../fonts/Material-Design-Iconic-Font.woff') format('woff'), url('../fonts/Material-Design-Iconic-Font.ttf') format('truetype'), url('../fonts/Material-Design-Iconic-Font.svg') format('svg');
  font-weight: 400;
  font-style: normal;
}
